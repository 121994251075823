<template>
    <b-modal id="contact-form" scrollable size="lg" hide-footer ref="modal">
        <template #modal-title>
            <h5 class="modal-title">Contact Us</h5>
        </template>
        <div class="row">
            <div class="col-lg-12">
                <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                <b-alert v-model="success" variant="success">
                    Thank you for your message. It has been sent.
                </b-alert>
                <spinner v-model="loading" ></spinner>

                <base-input
                    v-model="form.name"
                    :label="'Your full name'"
                    :input-classes="'form-control'"
                    :class="'form-group mb-2'"
                    :error="errors.name.message"
                ></base-input>

                <base-input
                    v-model="form.email"
                    type="email"
                    :label="'Email'"
                    :input-classes="'form-control'"
                    :class="'form-group mb-2'"
                    :error="errors.email.message"
                ></base-input>

                <div class="form-group">
                    <label class="form-control-label">Message</label>
                    <b-form-textarea
                        id="textarea"
                        v-model="form.message"
                        :class="{'is-invalid': errors.message.message}"
                        rows="1"
                        max-rows="6"
                    ></b-form-textarea>
                    <div class="invalid-feedback text-danger" v-if="errors.message.message"
                         v-text="errors.message.message"></div>
                </div>

                <vue-recaptcha
                               sitekey="6Ld4gb0hAAAAAEjHdkJLMpaYEpuMMUTK2oNj3PbT"
                               size="normal"
                               theme="light"
                               hl="tr"
                               @verify="recaptchaVerified"
                               @expired="recaptchaExpired"
                               @fail="recaptchaFailed"
                               ref="vueRecaptcha">
                </vue-recaptcha>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12 text-right modal-footer-border">
                <button type="button" @click="closeModal" class="btn btn-light mt-3">Close</button>
                <button type="button" @click="send" class="btn btn-primary mt-3">Submit</button>
            </div>
        </div>
    </b-modal>
</template>

<script>

import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue';
import { BFormTextarea } from 'bootstrap-vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'contact-form',
    components: {BAlert, Spinner, BFormTextarea, VueRecaptcha},
    mixins: [ProcessError],
    data() {
        return {
            loading: false,
            success: false,
            form: {
                name: null,
                email: null,
                message: null,
                recaptchaToken: null,
            },
            errors: {
                form: {message: '', show: false},
                name: {message: ''},
                email: {message: ''},
                message: {message: ''},
            },
        }
    },
    computed: {
        styleTags() {
            return this.video.style ? this.formData.tags[this.video.style.value] || [] : []
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('contact-form');
        },
        send() {
            this.success = false;
            this.resetErrors();
            this.loading = true;
            this.axios.post('./send-message.json', this.form).then(() => {
                this.success = true;
                this.recaptchaExpired();
                this.scrollTop();
                this.loading = false;
            }).catch(error => {
                this.processError(error);
                this.scrollTop();
            });
        },
        scrollTop() {
            this.$refs.modal.$refs.body.scrollTop = 0;
        },
        recaptchaVerified(token) {
            this.form.recaptchaToken = token;
        },
        recaptchaExpired() {
            this.form.recaptchaToken = null;
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
            //
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
